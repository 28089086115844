<template>
  <div>
    <mdb-modal
      frame
      style="background-color: transparent;"
      position="bottom"
      direction="top"
      :show="modal"
      @close="modal = false"
    >
      <mdb-modal-body :class="['text-center','red-text']">
        <i :class="['fas fa-times-circle']"></i>&nbsp;&nbsp;&nbsp;&nbsp;
        <span>{{modalMessage}}</span>
      </mdb-modal-body>
    </mdb-modal>
    <div class="content">
      <div class="row row-no-padding align-items-center p-0 box">
        <div class="chat-content w-100 m-0">
          <div class="h-100 row m-0 p-0">
            <button
              type="button"
              style="margin-top: 35px; margin-right: 37px;"
              class="close"
              aria-label="Close"
              @click="closeChat"
            >
              <span aria-hidden="true" class="pull-right">
                <img src="@/assets/images/Icon-Close.svg" alt class="close--icon" />
              </span>
            </button>
            <div class="col-md-4 m-0 p-0 d-flex flex-column left-side">
              <div class="d-flex flex-row buttons-container">
                <div
                  :class="['px-2','flex-even','status-button',statusButtonClass(0)]"
                  @click="updateSelectedStatus(0)"
                >{{statusArray[0]}}</div>
                <div
                  :class="['px-2','flex-even','status-button',statusButtonClass(1)]"
                  @click="updateSelectedStatus(1)"
                >{{statusArray[1]}}</div>
                <div
                  :class="['px-2 button_active','flex-even','status-button',statusButtonClass(2)]"
                  @click="updateSelectedStatus(2)"
                >
                  <div>{{statusArray[2]}}</div>
                  <span v-if="unreplayed.length>0">{{unreplayed.length}}</span>
                </div>
              </div>
              <div class="d-flex flex-row text-center search-container">
                <input
                  type="text"
                  class="flex-fill form-control mx-0 mb-2 text-field"
                  :placeholder="$t('views.search')"
                  v-model="filter"
                />
                <span class="search-icon">
                  <img src="../../assets/Icons/Icons/Icon-Search-black.svg" class="search-icon-img" />
                </span>
              </div>
              <div class="people-container flex-grow-1">
                <div
                  v-for="(user, index) in filteredUsers"
                  :key="user.uuid"
                  :class="['people',{'busy':checkIfBusy(user.uuid)}, index == targetArray.length - 1 ? 'people-no-margin' : '', selectedPerson.uuid == user.uuid ? 'selected-person' : 'not-selected-person',isUnreplayed(user)? 'unreplayed':'']"
                  @click="updateselectedPerson(user)"
                >
                  <div :class="['person-info white-text pl-2 pt-2']">
                    <p class="p-0 m-0">
                      {{user.first_name}} {{user.last_name}}
                      <span
                        v-if="user.supervised_booth_title || user.supervised_exhibit_booth_title ? true:false"
                      >({{user.supervised_booth_title ? user.supervised_booth_title : user.supervised_exhibit_booth_title}})</span>
                    </p>
                    <p class="p-0 m-0">{{user.company}}</p>
                  </div>
                  <div
                    :class="['row','row-no-padding','align-items-center','h-100','message-icon-container',selectedPerson.uuid == user.uuid ? 'selected-message-icon-container' : 'not-selected-message-icon-container']"
                  >
                    <img src="../../assets/Icons/Icons/Icon-Chat.svg" class="message-icon" />
                  </div>
                </div>
              </div>
            </div>
            <hr class="mobile-seperator" />
            <div v-if="selectedPerson.uuid" class="col-md-8 m-0 p-0 right-side">
              <div ref="messages-container" class="messages-container">
                <div v-for="(message,index) in chatHistory" :key="index" class="message">
                  <div
                    :class="['d-flex', message.sender === user.uuid ? 'justify-content-end' : 'justify-content-start']"
                  >
                    <div class="chat d-inline-flex flex-column py-2">
                      <div
                        :class="['p-2','message-box',message.sender === user.uuid ? 'sender-class' : 'reciever-class']"
                      >
                        <p v-html="message.chatMessage" v-linkified></p>
                      </div>
                      <div
                        :class="['time',message.sender === user.uuid ? 'text-right' : 'text-left']"
                      >
                        {{message.createdAt | moment('hh:mm')}}
                        <span
                          v-if="message.sender === selectedPerson.uuid"
                        >, {{selectedPerson.first_name}} {{selectedPerson.last_name}}</span>
                        <span v-else>, {{$t('views.me')}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex flex-row align-items-center input-container">
                <!-- <button type="button" class="btn px-2 icon-button"><i aria-hidden="true" class="fas fa-smile"></i></button> -->
                <!-- <EmojiContainer :append="append" class='emoji-container' /> -->
                <textarea
                  id="chatmessage"
                  :placeholder="selectedPerson.uuid? formatPlaceholder(selectedPerson): ''"
                  class="regular-input m-0 flex-grow-1 md-textarea input-box pl-2"
                  v-on:keyup.enter="send"
                  v-model="message"
                ></textarea>
                <button type="button" @click="send" class="btn massenger-button m-0 send-btn">
                  <div class="button-content">
                    <img src="../../assets/Icons/Icons/Icon-Send.svg" class="mr-2 send-icon" />
                    <span class="button-text">{{$t('views.send')}}</span>
                  </div>
                </button>
                <button
                  type="button"
                  class="btn massenger-button m-0 video-btn"
                  @click="goToVideo()"
                >
                  <div class="button-content">
                    <img src="../../assets/Icons/Icons/Icon-Video.svg" class="mr-2 video-icon" />
                    <span class="button-text">{{$t('views.video')}}</span>
                  </div>
                </button>
              </div>
            </div>
            <div class="no_chat" v-else>
              <div>
                <img src="@/assets/images/Icon-no-chat.svg" alt />
                <p>Wählen Sie eine Person aus der Liste links aus, um einen Live-Chat zu starten.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import EmojiContainer from 'components/shared/EmojiContainer.vue';
import { mdbModal, mdbModalBody } from 'mdbvue';
export default {
  name: 'Chat',
  props: {
    closeChat: Function,
    selectedUserId: String,
    user: Object,
    errorMessage: Object,
    messageNumbers: Number,
    chatMessage: Object,
    unreplayed: Array,
    removeFromUnreplayed: Function
  },
  components: {
    // EmojiContainer,
    mdbModal,
    mdbModalBody
  },
  data() {
    return {
      selectedPerson: {},
      message: '',
      prevMessage: '',
      filter: '',
      selectedStatus: -1,
      statusArray: [
        this.$t('views.visitors'),
        this.$t('views.exhibitors'),
        this.$t('views.active')
      ],
      visitors: [],
      exhibitors: [],
      onlineVisitors: [],
      onlineExhibitors: [],
      targetArray: [],
      filteredArray: [],
      tempHistory: [],
      chatHistory: [],
      messages: [],
      activ: [],
      onlineActiv: [],
      modal: false,
      modalMessage: '',
      firstTime: true,
      onlineActiveIds: [],
      today: new Date()
    };
  },
  async beforeMount() {
    this.$store.commit('getActiveUsers');
    if (this.unreplayed.length > 0) {
      this.updateSelectedStatus(2);
    } else {
      this.updateSelectedStatus(0);
    }
  },
  mounted() {},
  methods: {
    checkIfBusy(uuid) {
      return this.$store.state.isBusy.includes(uuid) ? true : false;
    },
    formatPlaceholder(user) {
      return `Sie schreiben ${user.first_name} ${user.last_name}`;
    },
    updateselectedPerson(user) {
      this.selectedPerson = user;
      this.removeFromUnreplayed(this.selectedPerson.uuid);
      //Removed the function to set chat on active when writing a message
      // this.updateSelectedStatus(2);
      this.message = '';
      this.getChatHistory();
    },
    getChatHistory() {
      this.$http
        .get(
          `/chat-history-sender?recipient=${this.selectedPerson.uuid}`
        )
        .then(resp => {
          this.tempHistory = resp.data;
          this.getRecievedHistory();
        })
        .catch(err => {
          console.log(err);
        });
    },
    getRecievedHistory() {
      this.$http
        .get(
          `/chat-history-recipient?sender=${this.selectedPerson.uuid}`
        )
        .then(resp => {
          this.tempHistory = this.tempHistory.concat(resp.data);
          this.sortHistory();
        })
        .catch(err => {
          console.log(err);
        });
    },
    sortHistory() {
      this.tempHistory.sort(
        (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
      );
      this.chatHistory = this.tempHistory;
      this.$nextTick(() => {
        this.$refs['messages-container'].scrollTop = this.$refs[
          'messages-container'
        ].scrollHeight;
      });
    },
    updateSelectedStatus(index) {
      this.selectedStatus = index;
      // this.selectedPerson = '';
    },
    statusButtonClass(status) {
      return this.selectedStatus == status
        ? 'selected-status'
        : 'not-selected-status';
    },
    send() {
      if (this.message.length > 0) {
        var today = new Date();

        window.websocket.send(
          JSON.stringify({
            wsMessageType: 'e2e',
            sender: this.user.uuid,
            recipient: this.selectedPerson.uuid,
            message: this.message
          })
        );
        this.prevMessage = this.message;
        this.chatHistory.push({
          chatMessage: this.message,
          sender: this.user.uuid,
          recipient: this.selectedPerson.uuid,
          createdAt: today
        });
        this.$nextTick(() => {
          this.$refs['messages-container'].scrollTop = this.$refs[
            'messages-container'
          ].scrollHeight;
          this.onlineActiveIds.includes(this.selectedPerson.uuid) ? '' : null;
        });
        this.message = '';
        if (this.selectedStatus !== 2) {
          this.updateselectedPerson(this.selectedPerson);
          this.updateSelectedStatus(2);
        }
      }
    },
    goToVideo() {
      if (this.selectedPerson) {
        var videoinfotext =
          'Ich habe gerade einen Video-Chat für Sie gestartet. Bitte drücken Sie auf den Knopf "Video an" um beizutreten.';
        this.message = videoinfotext;
        this.send();
        localStorage.removeItem('video-domain');
        localStorage.removeItem('room-name');
        this.closeChat();
        let baseURL = window.location.host;
        window.open(
          `http://${baseURL}/videochat/` + this.selectedPerson.uuid,
          '_blank'
        );
        //this.$router.push('/videochat/' + this.selectedPerson.uuid);
      }
    },
    isToday(date) {
      return (
        date.getDate() == this.today.getDate() &&
        date.getMonth() == this.today.getMonth() &&
        date.getFullYear() == this.today.getFullYear()
      );
    },
    padLeft(num) {
      if (num < 10) {
        return '0' + num;
      } else {
        return num;
      }
    },
    async updateTargetArray() {
      //GET All Users except exhibitors
      if (this.selectedStatus == 0) {
        this.targetArray = await this.$store.state.usersOnline.filter(
          user => !user.roles.includes('exhibitor')
        );
      }
      //GET only Exhibitors
      else if (this.selectedStatus == 1) {
        this.targetArray = await this.$store.state.usersOnline.filter(user =>
          user.roles.includes('exhibitor')
        );
      }
      //GET All active Chats
      else if (this.selectedStatus == 2) {
        let resp = await this.$http.get(
          '/chat-recipients?sender=' + this.$store.state.user.uuid
        );
        let activeUsers = [...this.unreplayed, ...resp.data];
        let users = this.$store.state.allUsers.filter(user =>
          activeUsers.includes(user.uuid)
        );
        users.map(user => {
          this.isUnreplayed(user)
            ? (user.has_new_message = true)
            : (user.has_new_message = false);
          return user;
        });
        users = users
          .sort((a, b) => a.has_new_message - b.has_new_message)
          .reverse();
        this.targetArray = users;
      }
    },
    isUnreplayed(user) {
      if (
        this.selectedPerson.uuid != user.uuid &&
        this.unreplayed.includes(user.uuid)
      ) {
        return true;
      } else return false;
    }
  },
  watch: {
    usersOnline() {
      this.updateTargetArray();
    },
    selectedStatus() {
      this.updateTargetArray();
    },
    errorMessage() {},
    chatMessage() {
      this.updateSelectedStatus(2);
      if (
        this.chatMessage.recipient == this.selectedPerson.uuid ||
        this.chatMessage.sender == this.selectedPerson.uuid
      ) {
        var time = new Date();
        this.chatHistory.push({
          chatMessage: this.chatMessage.message,
          sender: this.chatMessage.sender,
          recipient: this.chatMessage.recipient,
          createdAt: time
        });
        this.$nextTick(() => {
          this.$refs['messages-container'].scrollTop = this.$refs[
            'messages-container'
          ].scrollHeight;
        });
      }
    }
  },
  computed: {
    usersOnline() {
      return this.$store.state.usersOnline;
    },
    filteredUsers() {
      return this.filter
        ? this.targetArray.filter(
          user =>
            user.first_name
              .toLowerCase()
              .includes(this.filter.toLowerCase()) ||
            user.last_name
              .toLowerCase()
              .includes(this.filter.toLowerCase()) ||
            (user.company
              ? user.company.toLowerCase().includes(this.filter.toLowerCase())
              : false) ||
            (user.supervised_booth_title !== null
              ? user.supervised_booth_title
                .toLowerCase()
                .includes(this.filter.toLowerCase())
              : false) ||
            (user.supervised_exhibit_booth_title !== null
              ? user.supervised_exhibit_booth_title
                .toLowerCase()
                .includes(this.filter.toLowerCase())
              : false)
        )
        : this.targetArray;
    }
  }
};
</script>

<style lang='scss' scoped>
.no_chat {
  img {
    width: 87.84;
  }
  div {
    display: flex;
    flex-direction: column;
  }
  p {
    max-width: 324px;
    text-align: center;
    margin-top: 15px;
  }
  color: #9d9d9d;
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button_active {
  display: flex;
  justify-content: space-between;
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $primaryColor;
    color: white;
    border-radius: 50%;
    height: 20.69px;
    width: 20.69px;
  }
}
textarea.md-textarea-scroll {
  overflow-y: auto;
}

.flex-even {
  flex: 1;
}

.search-container {
  border-color: rgba(255, 255, 255, 0.7);
  width: 100%;
  padding-right: 30px;
}

.search-container .text-field {
  height: 34px;
  border-radius: 0%;
  background-color: rgba(0, 0, 0, 0);
  margin-bottom: 0 !important;
  color: white;
}

.search-icon {
  background-color: rgba(255, 255, 255, 0.7);
  width: 34px;
  position: relative;
}

.busy {
  opacity: 0.5;
  cursor: default;
}
.busy:hover {
  position: relative;
  cursor: not-allowed !important;
  &:after {
    content: "Beschäftigt";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    background: white;
  }
}

.search-icon-img {
  width: 17.49px;
  height: 17.49px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
textarea.md-textarea {
  padding: 0;
  resize: vertical;
  height: 30px;
  color: black;
  scrollbar-color: $scrollbarHaefele;
  scrollbar-width: thin;
}

hr {
  background-color: rgba(255, 255, 255, 0.5);
  width: 80%;
}

.mobile-seperator {
  display: none;
}

.close {
  position: absolute;
  font-size: 20px;
  right: 20px;
  top: 5px;
  z-index: 112;
  color: white;
  opacity: 1 !important;
  font-weight: 100;
}

.content {
  min-height: calc(100vh);
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0%);
  z-index: 119;
}

.box {
  z-index: 112;
  position: fixed;
  bottom: 0;
  width: calc(100% - 80px) !important;
  margin-left: 40px !important;
}
.chat-content {
  background-color: #000000cc;
  padding-top: 30px;
  padding-left: 30px;
  padding-right: 20px;
  padding-bottom: 70px;
  height: 60vh;
  position: relative;
  width: 100%;
}

.right-side {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  height: 100%;
}

.messages-container {
  padding-left: 20px !important;
  padding-right: 20px !important;
  margin-bottom: 10px !important;
  overflow-y: auto;
  scrollbar-color: $scrollbarHaefele;
  scrollbar-width: thin;
  height: calc(100% - 85px) !important;
  font-size: 0.75rem;
}

.input-container {
  width: 100%;
  margin-left: 20px !important;
  margin-right: 20px !important;
  padding-left: 0 !important;
  height: 37px;
}

.time {
  font-size: 11px;
  font-weight: lighter;
  color: rgba(255, 255, 255, 0.5);
  font-style: italic;
}

.time.text-left {
  margin-left: 5%;
}

.send-btn {
  background-color: $secondaryColor !important;
  margin-right: 20px !important;
}

.sender-class {
  background-color: #888888;
}

.reciever-class {
  background-color: #404040;
}

.message-box {
  color: white;
  margin-bottom: 7px;
  max-width: 50vw;
  font-size: 14px;
  padding: 10px !important;
}

.input-box {
  background-color: rgba(128, 128, 128, 0.7);
  color: white !important;
  font-weight: 400;
  font-size: 15px;
  border: 0;
  resize: none !important;
  height: 100% !important;
  scrollbar-color: $scrollbarHaefele;
  scrollbar-width: thin;
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.btn {
  background-color: white;
  font-size: 13px !important;
  text-transform: none;
  border-radius: 0px;
  padding: 0 !important;
}

.massenger-button {
  width: 140px;
  height: 100%;
  font-size: 16px !important;
  padding: 0 !important;
  position: relative;
}

.massenger-button:hover {
  background-color: $secondaryColor;
}

.icon-button:hover {
  background-color: $secondaryColor;
}

.left-side {
  max-height: 100%;
}

.people-container {
  padding-left: 0 !important;
  padding-right: 30px !important;
  overflow-y: auto;
  scrollbar-color: $scrollbarHaefele;
  scrollbar-width: thin;
  margin-top: 34px;
  /* max-height: 90%; */
}

.buttons-container {
  padding-right: 30px;
  margin-bottom: 20px;
}

.person-info {
  font-size: 16px;
  display: inline-block;
  white-space: nowrap;
  width: 84%;
  height: 100%;
  overflow: hidden !important;
  text-overflow: ellipsis;
  font-weight: 400;
  margin: 0 !important;
}

.people {
  margin-bottom: 20px !important;
  height: 65px;
  border: 1px solid;
  width: 100%;
  position: relative;
}

.people-no-margin {
  margin-bottom: 0px !important;
}

.not-selected-person {
  border-color: white;
}

.message-icon-container {
  width: 45px;
  position: absolute;
  right: 0;
  top: 0;
}

.message-icon {
  width: 25px;
  height: 24px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.not-selected-message-icon-container {
  background-color: white;
}

.people:hover {
  cursor: pointer;
  border-color: $secondaryColor;
}

.people:hover .message-icon-container {
  background-color: $secondaryColor;
}

.selected-message-icon-container {
  background-color: $secondaryColor !important;
}

.selected-person {
  border-color: $secondaryColor !important;
}

.message-icon {
  font-size: 22px;
  width: 100%;
  text-align: center;
}

.row-no-padding {
  margin-left: 0;
  margin-right: 0;
}

.not-selected-status {
  color: #c0c0c0;
  background-color: #696969;
}

.selected-status {
  color: white;
  background-color: $primaryColor;
}

.status-button {
  font-size: 16px;
  height: 34px;
  font-weight: 400;
  padding-top: 5px;
}

.status-button:hover {
  cursor: pointer;
}

.emoji-container {
  width: 37px;
  height: 100% !important;
}

.button-content {
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.unreplayed {
  position: relative;
  &::after {
    content: "";
    height: 12px;
    width: 12px;
    background: $primaryColor;
    border-radius: 50%;
    position: absolute;
    top: 12px;
    right: 5px;
  }
}

@media screen and (min-width: 1920px) {
  .chat-content {
    padding-left: 40px;
    padding-right: 20px;
    padding-top: 40px;
  }
}

@media screen and (max-width: 760px) {
  .chat-content {
    height: 100% !important;
    width: 100% !important;
    padding: 0 !important;
  }

  .buttons-container {
    padding: 15px;
    margin-top: 20px;
  }

  .search-container {
    padding: 15px;
  }

  .people-container {
    padding: 15px !important;
    max-height: 60vh;
  }

  .messages-container {
    padding: 15px !important;
    max-height: 60vh;
  }

  .content {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0%);
    z-index: 119;
    padding-top: 10vh;
    padding-left: 0px !important;
    padding-right: 0px !important;
    overflow-y: scroll !important;
    max-height: 100vh !important;
  }

  .button-content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .send-icon {
    margin-right: 0 !important;
  }

  .video-icon {
    margin-right: 0 !important;
  }

  .box {
    position: relative;
    margin-bottom: 50px !important;
  }

  .button-text {
    display: none;
  }

  .input-container {
    margin-left: 0px !important;
    margin-right: 0px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    margin-bottom: 20px !important;
  }

  .button-icon {
    margin-right: 0px !important;
    padding-right: 0px !important;
  }

  .massenger-button {
    width: 40px !important;
  }

  .close-container {
    right: 10px;
  }

  .mobile-seperator {
    display: block;
  }
}
</style>
